/* Navbar.css */

.navbar {
    position: absolute; /* Initially not sticky */
    top: 0;
    width: 100%;
    /* padding: 20px 40px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
    background: transparent;
    color: #fff;
    z-index: 1000;
  }
  .sub-navbar {
    /* height: 60px; */
    width: 100%;
    padding: 16px 32px;
    display: flex;
      justify-content: space-between;
  }
  .content-nav-link {
    display: flex;
      gap: 8px;
      align-items: center;
      gap: 32px;
  }
  
  .navbar--sticky {
    position: fixed; /* Sticky position */
    background: rgba(2,16,48);
    /* padding: 10px 40px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .nav-link-wrapper {
    /* font-size: large;   */
  }
  
  .nav-link {
    font-family: "Yuji Mai", serif !important;
    text-decoration: none;
    color: #fff;
    font-size: medium;
  }
  
  .disabled-cls {
    pointer-events: none; /* Prevent any interactions (clicks, hovers) */
    cursor: not-allowed;  /* Change the cursor to show it is not clickable */
    opacity: 0.6;        /* Make it look "disabled" by reducing opacity */
  }
  
  .products-page {
    position: fixed; /* Sticky position */
    background: rgba(2,16,48);
    /* padding: 10px 40px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .checkout-page {
    position: fixed; /* Sticky position */
    background: rgba(2,16,48);
    /* padding: 10px 40px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar__logo a {
    color: #fff;
    font-size: 24px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .navbar__links {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .navbar__links li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .navbar__links li a:hover {
    color: #f0a500;
  }
  
  /* Update Navbar.css */
  
  .navbar {
    transition: all 0.3s ease;
  }
  
  .navbar--sticky {
    transition: all 0.3s ease;
  }
  
  .main-log-img {
    width: 80px;
    height:80px
  }
  .main-warap-fr-img {
    display: flex;
  }
  .title-wrap {
    font-family: "Yuji Mai", serif !important;
    font-weight: 800 !important;
    font-size: 1.5rem !important;
  }
  
  .heading-mobile-link-wrap {
  
  }
  
  .heading-link-wrap {
  
  }