.photo-effects-container {
    display: flex;
    align-items: center;
    gap: 2rem;
    height: 400px;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Arial', sans-serif; /* Adjust font family as needed */
  }
  
  .text-content {
    width: 50%;
    height: 400px;
    /* flex: 1; */
    color: #333;
  }
  
  .text-content h1 {
    color: #03abeb;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 6px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .text-content p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .image-content {
    /* flex: 1; */
    width: 50%;
    height: 400px !important;
  }
  
  .image-content img {
    width: 100%;
    border-radius: 8px;
  }
  