.main-services-page-wrapper {
    /* padding-top: 70px; */
    width: 100%;
    height: auto;
}
.service-main-bg-img {
    background-image: url('/src/assets/images/Service-page-mainImg.jpg');
    /* Ensure the image is in the public folder */
    background-size: cover;
    background-position: center;
    height: 60vh;
    width: 100vw;
    position: relative;
    color: #fff;
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }