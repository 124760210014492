.text-main-wrap-sec {
    width: 70vw;
    font-family: Arial, sans-serif;
    /* Adjust based on the font in the image */
    font-size: 48px;
    /* Adjust size to match the image */
    font-weight: bold;
    align-items: center;
  }
  
  .text-container-sec {
    font-family: Arial, sans-serif;
    /* Adjust based on the font in the image */
    font-size: 48px;
    /* Adjust size to match the image */
    font-weight: bold;
    width: 50%;
    padding-left: 100px;
    align-items: center;
    display: flex;
  }
  
  .gradient-text-sec {
    /* background: linear-gradient(to right, #000, #8a2be2, #0000ff); */
    background: #fff;
    /* Adjust colors to match the image */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .normal-text-sec {
    color: #000033;
    /* Adjust color to match "Development" text */
  }
  
  .normal-second-text-sec {
    color: white;
    /* font-size: large;
      font-weight: 600; */
  }
  
  /* new Style startted.................................. */
  
  .gradient-main-text-wrap-sec {
    width: 60vw;
  }

  .dummyclsfor35vw {
    width: 35vw;
  }
  
  .first-txt-wrap-sec,
  .second-txt-wrap-sec,
  .third-txt-wrap-sec {
    width: 100%;
    display: flex;
      justify-content: space-around;
  }
  
  .second-txt-wrap-sec,
  .third-txt-wrap-sec {
    font-family: Arial, sans-serif;
      font-size: 52px;
      font-weight: bold;
      /* padding-bottom: 12px; */
      align-items: center;
      display: flex
  ;
  }
  .sales-cnt-design {
    justify-content: center;
    display: flex;
    padding-top: 52px;
    font-size: larger;
    font-weight: 800;
  }