/* Background.css */

.background-container {
    position: relative;  /* This allows child elements to be positioned on top of the video */
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    object-fit: cover;   /* Ensures the video covers the entire screen */
    transform: translate(-50%, -50%);
    z-index: -1;         /* Keeps the video behind any content you overlay */
  }
  