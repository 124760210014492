/* Style for Animated Text classes */

.first-cnt-web-mode {
    font-size: 56px;
    line-height: 64px;
    letter-spacing: 0px;
    font-weight: 700;
    text-align: center;
    color: rgb(255, 255, 255);
    word-break: normal;
    font-family: Galano Grotesque Alt, Adjusted Trebuchet MS Fallback, Trebuchet MS, sans-serif;
}

.second-snt-we-mode {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    color: rgb(255, 255, 255);
    word-break: normal;
    margin: 8px 0px;
}